import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

import { ICONS_SOURCE } from './constant';
import { SvgClassNameType } from './list';

export type IVariantIcon = keyof typeof ICONS_SOURCE;

interface IIconProps extends HTMLAttributes<HTMLElement> {
  variant: IVariantIcon;
  svgClassName?: SvgClassNameType;
  autoSize?: boolean;
  width?: string | number;
  height?: string | number;
}

export const Icon: FC<IIconProps> = ({
  className,
  width,
  height,
  svgClassName,
  variant,
  autoSize = true,
  ...props
}) => {
  const ImageIcon = ICONS_SOURCE[variant];
  return (
    <span className={classNames('inline-flex w-[1em] h-[1em]', className, { 'w-fit h-fit': autoSize })} {...props}>
      <ImageIcon data-testid={`icon-${variant}`} className={svgClassName} width={width} height={height} />
    </span>
  );
};
