import { HTMLAttributes, ReactNode } from 'react';

import {
  AccountIcon,
  ActivateIcon,
  ArrowDownUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  AssignmentsIcon,
  BackArrowIcon,
  NextArrowIcon,
  ButtonSearchIcon,
  CalendarIcon,
  CheckListIcon,
  CircleSuccessCheckIcon,
  ClientManagementIcon,
  CloseClientIcon,
  CloseIcon,
  CloseLabelIcon,
  CollapseIcon,
  CompanyIcon,
  ContactsIcon,
  DashListIcon,
  DeActivateIcon,
  DeleteIcon,
  DocIcon,
  DownArrowIcon,
  DownloadFileIcon,
  DownloadIcon,
  DropdownIcon,
  DropdownSearchIcon,
  DropdownSelectIcon,
  EditIcon,
  ErrorClosedIcon,
  FilterIcon,
  InfoIcon,
  LinkActionIcon,
  LinkDocumentIcon,
  LinkedIcon,
  LostWifiIcon,
  MapIcon,
  OnHoldIcon,
  PasswordResetIcon,
  PlusIcon,
  ReInviteIcon,
  RenameIcon,
  SearchIcon,
  SelectIcon,
  SeparatedLineIcon,
  SetClientICon,
  SettingIcon,
  SortASCIcon,
  SortDESCIcon,
  SquaresFourIcon,
  SvgClassNameType,
  ThreeDotIcon,
  UploadIcon,
  UserManagementIcon,
  UsersThreeIcon,
  ViewIcon,
  WarningHandIcon,
  ExportIcon,
  TrashIcon,
  UploadFileIcon,
} from './list';

type IIConElement = (
  props: Omit<HTMLAttributes<HTMLElement>, 'className'> & {
    className?: SvgClassNameType;
    width?: string | number;
    height?: string | number;
  }
) => ReactNode;

export const ICONS_SOURCE = {
  'arrow-downup': ArrowDownUpIcon as IIConElement,
  'arrow-left': ArrowLeftIcon as IIConElement,
  'arrow-right': ArrowRightIcon as IIConElement,
  'check-list': CheckListIcon as IIConElement,
  'back-arrow': BackArrowIcon as IIConElement,
  'next-arrow': NextArrowIcon as IIConElement,
  document: DocIcon as IIConElement,
  account: AccountIcon as IIConElement,
  company: CompanyIcon as IIConElement,
  contacts: ContactsIcon as IIConElement,
  'dash-list': DashListIcon as IIConElement,
  dropdown: DropdownIcon as IIConElement,
  filter: FilterIcon as IIConElement,
  info: InfoIcon as IIConElement,
  map: MapIcon as IIConElement,
  plus: PlusIcon as IIConElement,
  search: SearchIcon as IIConElement,
  'squares-four': SquaresFourIcon as IIConElement,
  'users-three': UsersThreeIcon as IIConElement,
  close: CloseIcon as IIConElement,
  'circle-success-check': CircleSuccessCheckIcon as IIConElement,
  disconnect: LostWifiIcon as IIConElement,
  'warning-hand': WarningHandIcon as IIConElement,
  'error-closed': ErrorClosedIcon as IIConElement,
  'three-dot': ThreeDotIcon as IIConElement,
  assignment: AssignmentsIcon as IIConElement,
  'password-reset': PasswordResetIcon as IIConElement,
  'on-hold': OnHoldIcon as IIConElement,
  're-invite': ReInviteIcon as IIConElement,
  'de-active': DeActivateIcon as IIConElement,
  active: ActivateIcon as IIConElement,
  'sort-asc': SortASCIcon as IIConElement,
  'sort-desc': SortDESCIcon as IIConElement,
  delete: DeleteIcon as IIConElement,
  'close-client': CloseClientIcon as IIConElement,
  'select-icon': SelectIcon as IIConElement,
  'separated-line': SeparatedLineIcon as IIConElement,
  setting: SettingIcon as IIConElement,
  'client-management': ClientManagementIcon as IIConElement,
  'user-management': UserManagementIcon as IIConElement,
  edit: EditIcon as IIConElement,
  calendar: CalendarIcon as IIConElement,
  'dropdown-search': DropdownSearchIcon as IIConElement,
  'set-client': SetClientICon as IIConElement,
  upload: UploadIcon as IIConElement,
  'down-arrow': DownArrowIcon as IIConElement,
  linked: LinkedIcon as IIConElement,
  'collapse-icon': CollapseIcon as IIConElement,
  'dropdown-select': DropdownSelectIcon as IIConElement,
  rename: RenameIcon as IIConElement,
  'button-search': ButtonSearchIcon as IIConElement,
  'download': DownloadIcon as IIConElement,
  'download-file': DownloadFileIcon as IIConElement,
  'close-label': CloseLabelIcon as IIConElement,
  view: ViewIcon as IIConElement,
  'link-document': LinkDocumentIcon as IIConElement,
  'link-action': LinkActionIcon as IIConElement,
  'arrow-up': ArrowUpIcon as IIConElement,
  export: ExportIcon as IIConElement,
  'trash': TrashIcon as IIConElement,
  'upload-file': UploadFileIcon as IIConElement,
};
