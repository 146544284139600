import { LoadingOutlined } from '@ant-design/icons';

import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import { Spin } from 'antd';
import classNames from 'classnames';

type ButtonPropType = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
  disabled?: boolean;
};

const Button = ({ children, className, disabled, type = 'button', isLoading = false, size = 'lg', ...rest }: ButtonPropType) => {
  return (
    <button
      className={classNames('font-roboto', size, className, {
        'opacity-40 cursor-not-allowed': disabled,
      })}
      disabled={disabled || isLoading}
      type={type}
      {...rest}
    >
      {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 12, color: 'white' }} spin />} />} {children}
    </button>
  );
};

export default Button;
